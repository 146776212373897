import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/auth.models';
import { UrlsService } from '../../services/urls.service';
@Injectable({ providedIn: 'root' })
export class AuthfakeauthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient,private url:UrlsService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {


  

/*
        return this.http.post<any>(`/users/authenticate`, { email, password })
        .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }
            return user;
        }));*/
    

  







            return     this.http.post<any>(this.url.login,
                JSON.stringify({  
                  login: email,
                  password: password,
                 }))    .pipe(map(user => {
                    if (user && user.token) {
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                    }
                    return user;
                }));
                 
                 
         



    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('key');
        localStorage.removeItem('token');
        localStorage.removeItem('login');
        localStorage.removeItem('iduser');
        localStorage.removeItem('nomuser');
        localStorage.removeItem('prenomuser');
        localStorage.removeItem('mail');
        localStorage.removeItem('typeuser');
        localStorage.removeItem('avatarvv');
        this.currentUserSubject.next(null);
    }
}
